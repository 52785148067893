@import '_colors.scss', '_text.scss', '_rwd.scss', '_breakpoints.scss';

.Content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;

    @include rwd($tabletBreakpoint) {
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
    }
}

.Description {
    @include label1;
    color: $base70;
    margin-bottom: 60px;
    text-align: center;
}

.TitleContent {
    font-size: 48px;
    font-weight: 600;
    text-align: center;

    @include rwd($desktopBreakpoint) {
        @include h1;
        margin-bottom: 8px;
    }
}
